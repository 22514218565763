import React, { useState, useEffect, useContext } from "react";
import { Container, Nav, Tab, Row, Col, Table, Card, Form, Button, Modal } from 'react-bootstrap';
import { AuthContext } from '../components/LoginPage/LoginPage.js';
import { FaTrash, FaPen , FaInfoCircle, FaLocationArrow  } from "react-icons/fa";
import useWindowSize from '../assets/config/WindowSize.js';
import { useMsal } from "@azure/msal-react";
import { getUserDetails } from "../assets/config/graph.js";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';



function OpportuniteForm() {
    const [newOpportunite, setNewOpportunite] = useState({
        soc: "",
        numero: "",
        raisonSociale: "",
        typeSection: "",
        typeClient: "",
        siret: "",
        responsable: "",
        });

    const [newEvenement, setNewEvenement] = useState({
        nom: "",
        type: "",
        descriptif: "",
        date: "",
    });

    const [editEvenement, setEditEvenement] = useState({ // Nouvel état pour le modal d'édition
        nom: "",
        type: "",
        descriptif: "",
        date: "",
    });

    const [initialOpportunite, setInitialOpportunite] = useState({ ...newOpportunite });
    const { instance, accounts } = useMsal();
    const [isModified, setIsModified] = useState(false);
    const location = useLocation();
    const size = useWindowSize();
    const isMobile = size.width <= 768;
    const opportuniteDetails = location.state && location.state.opportuniteDetails;
    const { userDetails } = useContext(AuthContext);
    const [etablissements, setEtablissements] = useState({});
    const [selectedEtab, setSelectedEtab] = useState("");
    const [opportuniteOptions, setOpportuniteOptions] = useState([]);
    const [selectedDescriptif, setSelectedDescriptif] = useState('');
    const [showDescriptifModal, setShowDescriptifModal] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [tempCode, setTempCode] = useState('');
    const [showHelpPopup, setShowHelpPopup] = useState(false);
    const [typeSocieteOptions, setTypeSocieteOptions] = useState([]);
    const [typeOptions, settypeOptions] = useState([]);
    const [typeSectionOptions, setTypeSectionOptions] = useState([]);
    const [evenements, setEvenements] = useState([]);
    const [initialEvenement, setInitialEvenement] = useState({ ...newEvenement });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [eventToDelete, setEventToDelete] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [eventToEdit, setEventToEdit] = useState(null);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [activeTab, setActiveTab] = useState("create");
    const handleHelpPopupShow = () => setShowHelpPopup(true);
    const handleHelpPopupClose = () => setShowHelpPopup(false);
    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [recipientEmail, setRecipientEmail] = useState('');
    const [isCreating, setIsCreating] = useState(true);

    const fetchEvenements = async () => {
      if (newOpportunite.numero) { 
          try {   
              const response = await axios.get(`/evenement?soc=${selectedEtab}&numero=${newOpportunite.numero}`);
              console.log('Événements récupérés :', response.data); // Ajoutez ce log
              setEvenements(response.data);  // Met à jour les événements affichés
          } catch (error) {
              console.error('Erreur lors de la récupération des événements :', error);
          }
      }
  };

    // Call fetchEvenements in useEffect to initially load events

    useEffect(() => {
      fetchEvenements(); // Call it here to fetch events initially
  }, [newOpportunite.numero, selectedEtab]);

    const handleSendEmail = async () => {
      // Assurez-vous que ces valeurs sont définies avant d'appeler la fonction
      const eventDetails = `Voici les détails de l'événement que vous avez demandé :\n\nNom : ${eventName}\nDate : ${eventDate}\nDescription : ${eventDescription}`;
      
      try {
          const response = await axios.post('/send-email', {
              to: recipientEmail,
              subject: 'Détails de l\'événement',
              body: eventDetails,
          });
          alert("E-mail envoyé avec succès !");
          setShowEmailModal(false);
      } catch (error) {
          console.error("Erreur lors de l'envoi de l'e-mail :", error);
          alert("Erreur lors de l'envoi de l'e-mail. Veuillez réessayer.");
      }
    };

    const handleShowEditModal = (evenement) => {
      setEventToEdit(evenement);
      setEditEvenement({
          nom: evenement.nom,
          type: evenement.type,
          descriptif: evenement.descriptif,
          date: evenement.date, // Assurez-vous que vous récupérez la date ici
          indice: evenement.indice // Assurez-vous que l'indice est inclus ici
      });
      setShowEditModal(true);
  };

      const handleUpdateEvent = (updatedEvent) => {
        setEvenements((prevEvenements) =>
            prevEvenements.map((evenement) =>
                evenement.indice === updatedEvent.indice ? updatedEvent : evenement
            )
        );
        setShowEditModal(false); // Ferme le modal après la mise à jour
    };

    const handleEdit = async () => {
      try {
          // Appelle l'API pour mettre à jour l'événement dans la base de données
          await axios.post(`/update_evenement`, {
              soc: eventToEdit.soc,
              numero: eventToEdit.numero,
              ...editEvenement // Cela doit contenir toutes les nouvelles informations, y compris la date
          });
  
          // Met à jour l'état local avec l'événement mis à jour
          const updatedEvent = { ...eventToEdit, ...editEvenement }; // Crée un nouvel objet événement mis à jour
          handleUpdateEvent(updatedEvent); // Appelle la fonction pour mettre à jour l'état
  
          // Ajoutez ici un appel pour récupérer les événements après la mise à jour
          fetchEvenements(); // Ajoutez cette ligne
      } catch (error) {
          console.error("Erreur lors de la modification de l'événement:", error);
      }
  };


    const handleShowDeleteModal = (evenement) => {
        setEventToDelete(evenement);
        setShowDeleteModal(true);
    };
    


    const handleDelete = async () => {
      try {
          await axios.post('/delete_evenement', {
              soc: eventToDelete.soc,
              numero: eventToDelete.numero,
              indice: eventToDelete.indice // Inclure l’indice pour cibler l'événement précis
          });
          fetchEvenements(); // Call the fetchEvenements function after deletion
          setShowDeleteModal(false);
      } catch (error) {
          console.error("Erreur lors de la suppression de l'événement:", error);
      }
    };
    
    const confirmDelete = () => {
      if (eventToDelete) {
          handleDelete(); // Supprime l'article sélectionné
      }
      setShowDeleteModal(false); // Ferme la modale après confirmation
    };


    useEffect(() => {
        const fetchTypeClientData = async () => {
        try {
            const response = await axios.get('/typeclient');
            const typeClientData = response.data;
            const options = typeClientData.map((item) => ({
            value: item.typeClient,
            label: item.libelle,
            }));
            setTypeSocieteOptions(options);
        } catch (error) {
            console.error('Error fetching type client data:', error);
        }
        };
        fetchTypeClientData();
    }, []);

    useEffect(() => {
        const fetchtypeData = async () => {
        try {
            const response = await axios.get('/typeEvenement');
            const typeData = response.data;
            const options = typeData.map((item) => ({
            value: item.type,
            label: item.libelle,
            }));
            settypeOptions(options);
        } catch (error) {
            console.error('Error fetching type client data:', error);
        }
        };
        fetchtypeData();
    }, []);

    useEffect(() => {
        const fetchTypeSectionData = async () => {
        try {
            const response = await axios.get('/section');
            const typeSectionData = response.data;
            const options = typeSectionData.map((item) => ({
            value: item.typeSection,
            label: item.libelle,
            }));
            setTypeSectionOptions(options);
        } catch (error) {
            console.error('Error fetching type client data:', error);
        }
        };
        fetchTypeSectionData();
    }, []);

    useEffect(() => {
        const fetchUserProfile = async () => {
          if (accounts.length > 0) {
            try {
              const userDetailsResponse = await getUserDetails(instance, accounts);
              setUserProfile(userDetailsResponse);
            } catch (error) {
              console.error("Error fetching user details: ", error);
            }
          }
        };
    
        fetchUserProfile();
    }, [instance, accounts]);
    

    useEffect(() => {
        const fetchOpportunites = async () => {
            try {
            const response = await axios.get(`/opportunite?soc=${selectedEtab}`);
            const opportunitesList = response.data.map(Opportunite => ({
                value: Opportunite.numero,
                label: `${Opportunite.numero} - ${Opportunite.raisonSociale}`,
            }));
            setOpportuniteOptions(opportunitesList);
            } catch (error) {
            console.error('Erreur lors de la récupération des articles :', error);
            }
        };
        
        if (selectedEtab) {
            fetchOpportunites();
        }
    }, [selectedEtab]);

    useEffect(() => {
        if (userDetails?.mail) {
        axios.get(`/etab_by_mail?email=${userDetails.mail}`)
            .then(response => {
            const etabData = {};
            response.data.forEach(etab => {
                etabData[etab.etab_soc] = { etab_nom: etab.etab_nom };
            });
            setEtablissements(etabData);
            if (response.data.length > 0) {
                setSelectedEtab(response.data[0].etab_soc);
            }
            })
            .catch(error => console.error('Error fetching data:', error));
        }
    }, [userDetails.mail]);

    const handleEtabChange = (selectedOption) => {
      setSelectedEtab(selectedOption ? selectedOption.value : ""); // Update the selected value
    };


  const handleInputChangeOpportunite = (e) => {
    const { name, value } = e.target;
    setIsModified(value !== initialOpportunite[name]);
    setNewOpportunite({ ...newOpportunite, [name]: value });
  };

  const handleInputChangeEvenement = (selectedOption, { name }) => {
    const value = selectedOption ? selectedOption.value : ""; // Get value from the selected option
    setIsModified(value !== initialEvenement[name]); // Compare with initialEvenement
    setNewEvenement({ ...newEvenement, [name]: value });
  };

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const handleClearForm = () => {
    setNewOpportunite({
        soc: "",
        numero: "",
        raisonSociale: "",
        typeSection: "",
        typeClient: "",
        siret: "",
        responsable: "",
    });
    setInitialOpportunite({});
    setIsModified(false);
    setSelectedEtab("");
    setTempCode("");
    setNewEvenement({
        nom: "",
        type: "",
        descriptif: "",
        date: "",
    });
    setInitialEvenement({
        nom: "",
        type: "",
        descriptif: "",
        date: "",
    });
    setOpportuniteOptions([]);
    setEvenements([]); // Réinitialisation de l'historique des événements
};

const handleCreateOrUpdateOpportunite = async () => {
  try {
      const opportuniteData = {
          ...newOpportunite,
          soc: selectedEtab,
          responsable: newOpportunite.responsable || (userProfile ? `${userProfile.givenName} ${userProfile.surname}` : ""),
          numero: newOpportunite.numero.toString(),
          siret: newOpportunite.siret.toString(),
      };

      if (!isCreating) {
          // Si nous ne sommes pas en mode création, nous mettons à jour l'opportunité
          await axios.post(`/update_opportunite`, opportuniteData);
          alert("Opportunité modifiée avec succès !");
      } else {
          // Sinon, nous créons une nouvelle opportunité
          await axios.post(`/create_opportunite`, opportuniteData);
          alert("Opportunité créée avec succès !");
      }
      handleClearForm();
  } catch (error) {
      console.error("Erreur lors de la création ou de la mise à jour de l'opportunité :", error);
      alert("Erreur lors de la création ou de la mise à jour de l'opportunité. Veuillez réessayer.");
  }
};


const handleValidation = async () => {
  try {
      if (!newOpportunite.numero) {
          alert("Veuillez sélectionner une opportunité avant de créer un événement.");
          return;
      }

      if (!newEvenement.nom || !newEvenement.type || !newEvenement.descriptif) {
          alert("Veuillez remplir tous les champs de l'événement.");
          return;
      }

      const evenementData = {
          ...newEvenement,
          soc: newOpportunite.soc,
          numero: newOpportunite.numero,
          // Ne pas inclure `indice` ici
      };

      const response = await axios.post('/create_evenement', evenementData);

      alert("Événement créé avec succès !");
      setNewEvenement({
          nom: "",
          type: "",
          descriptif: "",
          date: "",
      });
      fetchEvenements();

  } catch (error) {
      if (error.response) {
          console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
          console.error("Erreur de requête envoyée :", error.request);
      } else {
          console.error("Erreur inconnue :", error.message);
      }
      alert("Erreur lors de la soumission de l'événement. Veuillez réessayer.");
  }
};



const handleOpportuniteChange = async (selectedOption) => {
  if (selectedOption) {
      try {
          const response = await axios.get(`/opportunite_by?soc=${selectedEtab}&numero=${selectedOption.value}`);
          const opportuniteData = response.data[0];
          setNewOpportunite({
              soc: opportuniteData.soc,
              numero: opportuniteData.numero,
              siret: opportuniteData.siret,
              raisonSociale: opportuniteData.raisonSociale,
              typeSection: opportuniteData.section,
              typeClient: opportuniteData.typeClient,
              responsable: opportuniteData.responsable,
          });

          // Récupère les événements de l'opportunité sélectionnée
          fetchEvenements();
          setIsCreating(false); // Passer en mode modification
      } catch (error) {
          console.error('Erreur lors de la récupération des détails de l\'opportunité:', error);
      }
  } else {
      handleClearForm();
      setIsCreating(true); // Passer en mode création
  }
};

  const renderForm = () => (
    <Container fluid>
        <Modal show={showDescriptifModal} onHide={() => setShowDescriptifModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Détails de l'Événement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{selectedDescriptif}</p>
            </Modal.Body>
        </Modal>
        <Modal show={showEmailModal} onHide={() => setShowEmailModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Envoyer un E-mail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <label>Adresse E-mail du Destinataire</label>
                        <Form.Control
                            type="email"
                            value={recipientEmail}
                            onChange={(e) => setRecipientEmail(e.target.value)}
                            placeholder="Entrez l'adresse e-mail"
                        />
                    </Form.Group>
                    <Form.Group>
                        <label>Message</label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            defaultValue="Voici les détails de l'événement que vous avez demandé."
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowEmailModal(false)}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={handleSendEmail}>
                    Envoyer
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmer la suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Êtes-vous sûr de vouloir supprimer cet événement ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                    Annuler
                </Button>
                <Button variant="danger" onClick={confirmDelete}>
                    Supprimer
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Modifier l'Événement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <label>Libelle</label>
                        <Form.Control
                            type="text"
                            name="nom_modal"
                            value={editEvenement.nom} // Utilisez editEvenement
                            onChange={(e) => setEditEvenement({ ...editEvenement, nom: e.target.value })} // Mettez à jour editEvenement
                        />
                    </Form.Group>
                    <Form.Group>
                        <label>Type d'événement</label>
                        <Form.Control as="select" name="type_modal" value={editEvenement.type} onChange={(e) => setEditEvenement({ ...editEvenement, type: e.target.value })}>
                            {typeOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <label>Date</label>
                        <Form.Control
                            type="date"
                            name="date_modal"
                            value={editEvenement.date} // Utilisez editEvenement
                            onChange={(e) => setEditEvenement({ ...editEvenement, date: e.target.value })} // Mettez à jour editEvenement
                        />
                    </Form.Group>
                    <Form.Group>
                        <label>Descriptif</label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="descriptif_modal"
                            value={editEvenement.descriptif} // Utilisez editEvenement
                            onChange={(e) => setEditEvenement({ ...editEvenement, descriptif: e.target.value })} // Mettez à jour editEvenement
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={handleEdit}>
                    Enregistrer les modifications
                </Button>
            </Modal.Footer>
        </Modal>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4" style={{ fontFamily: "'Century Gothic', sans-serif", fontSize: '1.5rem', fontWeight: 'bold', color: '#113c60', marginBottom: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Opportunités</span>
                  <div style={{ marginLeft: 'auto' }}>
                    <FaInfoCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleHelpPopupShow} />
                  </div>
                </div>
              </Card.Title>
              <Modal show={showHelpPopup} onHide={handleHelpPopupClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Aide</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Pour compléter le formulaire, veuillez suivre les instructions ci-dessous :</p>
                  <ul>
                    <li>Vérifiez les coordonnées</li>
                    <li>Renseignez tous les champs</li>
                    <li>Détaillez les services/produits</li>
                    <li>Revérifiez les informations</li>
                  </ul>
                  <p>Chaque champ est crucial pour la validité de l'opportunité.</p>
                </Modal.Body>
              </Modal>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md="4">
                    <Form.Group>
                      <label>Etablissement</label>
                      <Select
                        value={selectedEtab ? { value: selectedEtab, label: `${selectedEtab} - ${etablissements[selectedEtab].etab_nom}` } : null} // Set current value
                        onChange={handleEtabChange}
                        options={Object.keys(etablissements).map((key) => ({
                          value: key,
                          label: `${key} - ${etablissements[key].etab_nom}`
                        }))}
                        placeholder="Sélectionner un établissement"
                        noOptionsMessage={() => "Pas de résultats"}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                        <label>N° d'opportunité</label>
                        <div style={{ position: 'relative' }}>
                            {!newOpportunite.isCustom ? (
                                <Select
                                    placeholder="Choisir une opportunité..."
                                    options={opportuniteOptions}
                                    value={opportuniteOptions.find(opt => opt.value === newOpportunite.numero)}
                                    onChange={handleOpportuniteChange}
                                    onInputChange={(inputValue) => {
                                        // Met à jour le champ temporaire pour stocker la saisie de l'utilisateur
                                        setTempCode(inputValue);
                                    }}
                                    onBlur={() => {
                                        // Vérifie si la saisie complète correspond à une opportunité existante
                                        const optionExiste = opportuniteOptions.some(opt => opt.label.toLowerCase() === tempCode.toLowerCase());

                                        // Si l'option n'existe pas, passe en mode "input" et enregistre le numéro saisi
                                        if (!optionExiste && tempCode) {
                                            setNewOpportunite(prev => ({ ...prev, isCustom: true, numero: tempCode }));
                                        }
                                    }}
                                    isClearable={true}
                                    noOptionsMessage={() => "Il n'y a pas d'opportunité sous ce code"}
                                />
                            ) : (
                                <Form.Control
                                    type="text"
                                    placeholder="Créer une nouvelle opportunité..."
                                    value={newOpportunite.numero}
                                    onChange={(e) => setNewOpportunite(prev => ({ ...prev, numero: e.target.value }))}
                                    onBlur={() => {
                                        // Si le champ est vide, retourne en mode Select
                                        if (!newOpportunite.numero) {
                                            setNewOpportunite(prev => ({ ...prev, isCustom: false }));
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </Form.Group>
                </Col>
                <Col md="4">
                    <Form.Group>
                        <label>Responsable</label>
                        <Form.Control
                        type="text"
                        disabled
                        name="responsable"
                        value={newOpportunite.responsable || (userProfile ? `${userProfile.givenName} ${userProfile.surname}` : "")}
                        onChange={handleInputChangeOpportunite}
                        />
                    </Form.Group>
                    </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Form.Group>
                      <label>Raison Sociale</label>
                      <Form.Control
                        type="text"
                        name="raisonSociale"
                        value={newOpportunite.raisonSociale}
                        onChange={handleInputChangeOpportunite}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label>Siret</label>
                      <Form.Control
                        type="text"
                        name="siret"
                        value={newOpportunite.siret}
                        onChange={handleInputChangeOpportunite}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label>Type de société</label>
                      <Form.Control as="select" name="typeClient" value={newOpportunite.typeClient} onChange={handleInputChangeOpportunite}>
                        {typeSocieteOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                      <Form.Group>
                        <label>Secteur d'activité</label>
                        <Form.Control as="select" name="typeSection" value={newOpportunite.typeSection} onChange={handleInputChangeOpportunite}>
                            {typeSectionOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                            ))}
                        </Form.Control>
                     </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-end">
                    <Col md="3" className="text-end">
                        <Button className="mt-3" variant="primary" onClick={handleCreateOrUpdateOpportunite}>
                            {isCreating ? "Créer Opportunité" : "Modifier Opportunité"}
                        </Button>
                    </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Tab.Container activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="create">Création</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="histo">Historique Evenements</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="create">
                    <Form>
                      <Row className="align-items-center">
                        <Col md="4">
                            <Form.Group>
                                <label>Libelle</label>
                                <Form.Control
                                    type="text"
                                    name="nom"
                                    value={newEvenement.nom}
                                    onChange={handleInputChangeEvenement}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group>
                            <label>Type d'événement</label>
                            <Select
                              value={typeOptions.find((option) => option.value === newEvenement.type)} // Set the current value
                              onChange={(selectedOption) => handleInputChangeEvenement(selectedOption, { name: "type" })}
                              options={typeOptions}
                              placeholder="Choisir une option..."
                              noOptionsMessage={() => "Pas de résultats"}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group>
                            <label>Date</label>
                            <Form.Control
                              type="date"
                              name="date"
                              value={newEvenement.date}
                              onChange={handleInputChangeEvenement}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <label>Descriptif</label>
                            <Form.Control
                              as="textarea"
                              rows={5}
                              name="descriptif"
                              value={newEvenement.descriptif}
                              onChange={handleInputChangeEvenement}
                              maxLength={500} // Limite de 500 caractères
                            />
                            <small>{newEvenement.descriptif.length}/500 caractères</small> {/* Compteur de caractères */}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="justify-content-end">
                        <Col md="3" className="text-end">
                          <Button className="mt-3" variant="primary" onClick={handleValidation}>
                            Valider l'evenement
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Tab.Pane>
                    <Tab.Pane eventKey="histo">
                    <Row>
                        <Col md="12">
                        <Table className="table-hover table-striped">
                            <thead className='text-secondary'>
                            <tr>
                                <th>Libelle</th>
                                <th>Type d'Evenement</th>
                                <th>Descriptif</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                              {evenements.map((evenement, index) => (
                                <tr key={index}>
                                  <td>{evenement.nom}</td> {/* Utilisez le libellé ici */}
                                  <td>{evenement.type}</td>
                                  <td>
                                    <span
                                      style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                                      onClick={() => {
                                        setSelectedDescriptif(evenement.descriptif);
                                        setShowDescriptifModal(true);
                                      }}
                                    >
                                      Voir le descriptif
                                    </span>
                                  </td>
                                  <td>{formatDate(evenement.date)}</td> {/* Formatez la date ici */}
                                  <td>
                                    <div>
                                      <FaPen
                                        className="text-warning"
                                        style={{ cursor: "pointer ", marginRight: "10px" }}
                                        onClick={() => handleShowEditModal(evenement)}
                                      />
                                      <FaTrash
                                        className="text-danger"
                                        style={{ cursor: "pointer", marginRight: "10px" }}
                                        onClick={() => handleShowDeleteModal(evenement)}
                                      />
                                      <FaLocationArrow 
                                        className="text-info"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setShowEmailModal(true);
                                          setRecipientEmail(''); // Réinitialiser l'email à chaque ouverture
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                        </Table>
                        </Col>
                    </Row>
                    </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  return (
    <div>
      {renderForm()}
    </div>
  );
}

export default OpportuniteForm;
