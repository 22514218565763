//import './App.css';
import React, { useState, useEffect , useContext } from 'react';
import axios from 'axios'
import background from '../assets/img/bg.png';
import logo from '../assets/img/logo.jpg';
import arialFont from '../assets/fonts/Arial.ttf'
import arialBoldFont from '../assets/fonts/arial_Bold.ttf'
import {Document, Page, View, Text, Image, Font, PDFViewer, StyleSheet} from '@react-pdf/renderer';
import { cloneElement } from 'react';
import { useLocation } from 'react-router-dom';

Font.register({
  family: "Arial",
  src: arialFont,
});

Font.register({
  family: "ArialBold",
  src: arialBoldFont,
});

const Br = () => "\n";
const Tab = () => "\t";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    position: "absolute",
    width: "100%",
  },
  pageBackgroundContainer: {
    position: "relative",
    padding: "30",
    paddingBottom: "100",
    width: "100%",
    alignItems : "center",

  },
  imageBackground: {
    height: "100%",
    width: "100%",
  },
  imageLogo: {
    marginLeft: 105,
    marginTop: 30,
    height: "60%",
    width: "20%",
  },
  text_logo: {
    marginLeft: 95,
    marginTop: 2,
    fontSize: 9,
    fontFamily: "Arial",
    color: "#73636d",
  },
  societe: {
    marginLeft: 32,
    marginTop: 105,
    fontSize: 8,
    fontFamily: "Arial",
  },
  numero_document: {
    marginLeft: 45,
    marginTop: 140,
    fontSize: 9,
    fontFamily: "Arial",
  },
  corps_numero_document: {
    marginLeft: 47,
    marginTop: 155,
    fontSize: 9,
    fontFamily: "ArialBold",
  },
  numero_compte: {
    marginLeft: 145,
    marginTop: 140,
    fontSize: 9,
    fontFamily: "Arial",
  },
  corps_numero_compte: {
    marginLeft: 150,
    marginTop: 155,
    fontSize: 9,
    fontFamily: "ArialBold",
  },
  date: {
    marginLeft: 245,
    marginTop: 140,
    fontSize: 9,
    fontFamily: "Arial",
  },
  corps_date: {
    marginLeft: 227,
    marginTop: 155,
    fontSize: 9,
    fontFamily: "ArialBold",
  },
  titre: {
    marginLeft: 90,
    marginTop: 175,
    fontSize: 18,
    fontFamily: "ArialBold",
  },
  affaire: {
    marginLeft: 39,
    marginTop: 240,
    fontSize: 10,
    fontFamily: "Arial",
  },
  corps_affaire: {
    marginLeft: 125,
    marginTop: 241,
    fontSize: 9,
    fontFamily: "Arial",
    color: "#73636d",
  },
  reference: {
    marginLeft: 250,
    marginTop: 240,
    fontSize: 10,
    fontFamily: "Arial",
  },
  corps_reference: {
    marginLeft: 330,
    marginTop: 241,
    fontSize: 9,
    fontFamily: "Arial",
    color: "#73636d",
  },
  page: {
    marginLeft: 525,
    marginTop: 240,
    fontSize: 10,
    fontFamily: "Arial",
  },
  qte: {
    marginLeft: 62,
    marginTop: 260,
    fontSize: 9,
    fontFamily: "Arial",
  },
  corps_qte: {
    marginLeft: 52,
    marginRight: 530,
    marginTop: 280,
    fontSize: 9,
    fontFamily: "Arial",
  },
  us: {
    marginLeft: 95,
    marginTop: 260,
    fontSize: 9,
    fontFamily: "Arial",
  },
  corps_us: {
    marginLeft: 95,
    marginTop: 280,
    fontSize: 9,
    fontFamily: "Arial",
  },
  code: {
    marginLeft: 203,
    marginTop: 260,
    fontSize: 9,
    fontFamily: "Arial",
  },
  delai: {
    marginLeft: 420,
    marginTop: 260,
    fontSize: 9,
    fontFamily: "Arial",
  },
  corps_delai: {
    marginLeft: 409,
    marginTop: 280,
    fontSize: 9,
    fontFamily: "Arial",
  },
  corps: {
    marginLeft: 115,
    marginRight: 195,
    marginTop: 280,
    fontSize: 9,
    fontFamily: "Arial",
  },
  pb: {
    marginLeft: 465,
    marginTop: 260,
    fontSize: 9,
    fontFamily: "Arial",
  },
  corps_pb: {
    marginLeft: 463,
    marginTop: 280,
    fontSize: 9,
    fontFamily: "Arial",
  },
  montant: {
    marginLeft: 520,
    marginTop: 260,
    fontSize: 9,
    fontFamily: "Arial",
  },
  corps_montant: {
    marginLeft: 510,
    marginTop: 280,
    fontSize: 9,
    fontFamily: "Arial",
  },
  libelle_livraison: {
    marginLeft: 296,
    marginTop: 35,
    fontSize: 12,
    fontFamily: "Arial",
  },
  data_client: {
    marginLeft: 310,
    marginTop: 55,
    fontSize: 10,
    fontFamily: "Arial",
  },
  data_client_2: {
    marginLeft: 300,
    marginTop: 145,
    fontSize: 12,
    fontFamily: "Arial",
  },
  data_client_3: {
    marginLeft: 300,
    marginTop: 210,
    fontSize: 8,
    fontFamily: "Arial",
  },
  base: {
    marginLeft: 265,
    marginTop: 634,
    fontSize: 10,
    fontFamily: "Arial",
  },
  tva: {
    marginLeft: 265,
    marginTop: 654,
    fontSize: 9,
    fontFamily: "Arial",
  },
  tva1_bis: {
    marginLeft: 390,
    marginTop: 620,
    fontSize: 9,
    fontFamily: "Arial",
  },
  montant_ht: {
    marginLeft: 390,
    marginTop: 605,
    fontSize: 9,
    fontFamily: "Arial",
  },
  total: {
    marginLeft: 390,
    marginTop: 650,
    fontSize: 9,
    fontFamily: "ArialBold",
  },
  unite_eur_1: {
    marginLeft: 470,
    marginTop: 605,
    fontSize: 9,
    fontFamily: "Arial",
  },
  unite_eur_2: {
    marginLeft: 470,
    marginTop: 620,
    fontSize: 9,
    fontFamily: "Arial",
  },
  unite_eur_3: {
    marginLeft: 470,
    marginTop: 652,
    fontSize: 9,
    fontFamily: "Arial",
  },
  montant_1: {
    textAlign:"right",
    marginRight: 35,
    marginTop: 605,
    fontSize: 8,
    fontFamily: "ArialBold",
  },
  montant_tva_2: {
    textAlign:"right",
    marginRight: 35,
    marginTop: 620,
    fontSize: 9,
    fontFamily: "Arial",
  },
  montant_total_3: {
    textAlign:"right",
    marginRight: 35,
    marginTop: 652,
    fontSize: 8,
    fontFamily: "ArialBold",
  },
  corps_tva: {
    textAlign:"right",
    marginRight: 215,
    marginTop: 654,
    fontSize: 9,
    fontFamily: "Arial",
  },
  dir: {
    marginLeft: 33,
    marginTop: 680,
    fontSize: 10,
    fontFamily: "Arial",
  },
  service: {
    marginLeft: 163,
    marginTop: 680,
    fontSize: 10,
    fontFamily: "Arial",
  },
  reglement: {
    marginLeft: 270,
    marginTop: 692,
    fontSize: 10,
    fontFamily: "Arial",
  },
  VISA1: {
    marginLeft: 33,
    marginTop: 715,
    fontSize: 10,
    fontFamily: "Arial",
  },
  VISA2: {
    marginLeft: 165,
    marginTop: 715,
    fontSize: 10,
    fontFamily: "Arial",
  },
  banque: {
    marginLeft: 130,
    marginTop: 765,
    fontSize: 7,
    fontFamily: "ArialBold",
  }
});

function App() {
  const location = useLocation();
  const {soc, numero} = location.state

  return (
    <div className="App">
      <PDFViewer height="750" width="100%">
        <MyPDF 
        societe={soc}
        numerodocument={numero}/>
      </PDFViewer>
    </div>
  );
}

const MyPDF = (params) => {

  const soc = params.societe;
  const numero = params.numerodocument;

  const [enteteDocument, setEnteteDocument] = useState({});
  const [lignesDocument, setLignesDocument] = useState({});
  const [detailsEtab, setDetailsEtab] = useState({});
  const [detailsClient, setDetailsClient] = useState({});

  const fetchDocument = async (soc, numero) => {
    try {
      const response = await axios.get(`http://localhost:5000/devis/${soc}/${numero}`);
  
      setEnteteDocument(response.data["entete"]); 
      setLignesDocument(response.data["lignes"]); 
  
    } catch (error) {
      console.error("Erreur lors de la récupération des informations du document :", error);
    }
  };
  
  useEffect(() => {
    if (soc && numero) {  // Vérification stricte pour éviter les valeurs nulles, vides ou 0
      fetchDocument(soc, numero);
    }
  }, [soc, numero]);  // Se déclenche uniquement quand `soc` ou `numero` changent

  const fetchEtab = async (soc) => {
    try {
      const response = await axios.get(`/etab_by_soc?soc=${soc}`);
      setDetailsEtab(response.data[0]);
    } catch (error) {
      console.error("Erreur lors de la récupération des informations clients :", error);
    }
  };
  
  useEffect(() => {
    if (soc) {
      fetchEtab(soc);
    }
  }, [soc]);

  const fetchClient = async (soc, numero) => {
    try {
      const response = await axios.get(`/client_by_number?soc=${soc}&numero=${numero}`);
      setDetailsClient(response.data[0]);
    } catch (error) {
      console.error("Erreur lors de la récupération des informations clients :", error);
    }
  };
  
  useEffect(() => {
    if (enteteDocument && enteteDocument.compte) {
      fetchClient(soc, enteteDocument.compte);
    }
  }, [enteteDocument, soc]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Document>
      <Page size="A4">
      <View style={styles.pageBackgroundContainer}>
        <Image src={background} style={styles.imageBackground} />
      </View>
      <View style={styles.container}>
        <Image src={logo} style={styles.imageLogo}/>
        <Text style={styles.text_logo}>{detailsEtab.nom ? detailsEtab.nom.toUpperCase() : 'N/A'}</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.societe}>{detailsEtab.nom ? detailsEtab.nom.toUpperCase() : 'N/A'}<Br/>{detailsEtab.rue1 ? detailsEtab.rue1.toUpperCase() : 'N/A'}<Br/>{detailsEtab.code_postal ? detailsEtab.code_postal.toUpperCase() + " " : "N/A" + " "}{detailsEtab.ville ? detailsEtab.ville.toUpperCase() : 'N/A'}</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.numero_document}>N° Document</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.corps_numero_document}>OD{numero}</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.numero_compte}>N° Compte</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.corps_numero_compte}>{detailsClient.cpt ? detailsClient.cpt : "N/A"}</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.date}>Date</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.corps_date}>{enteteDocument.date_creation ? formatDate(enteteDocument.date_creation.split('T')[0]) : "N/A"}</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.titre}>OFFRE de PRIX</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.affaire}>Affaire suivie par : </Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.corps_affaire}>Frederic DI-CESARE</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.reference}>Vos références : </Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.corps_reference}>BIZET</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.page}>Page #</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.montant_1}>900,00</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.montant_tva_2}>180,00</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.montant_total_3}>1 080,00</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.qte}>Qté</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.corps_qte}>9999999</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.us}>Us</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.corps_us}>PI</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.code}>Code article & Libellé</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.delai}>Délai</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.corps_delai}>00/00/0000</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.pb}>Prix Brut</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.corps_pb}>00000.00</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.montant}>Montant</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.corps_montant}>0000000000</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.corps}>1<Br/>2<Br/>3<Br/>4<Br/>5<Br/>6<Br/>7<Br/>8<Br/>9<Br/>11<Br/>12<Br/>13<Br/>14<Br/>15<Br/>16<Br/>17<Br/>18<Br/>19<Br/>20<Br/>21<Br/>22<Br/>23<Br/>24<Br/>25<Br/>26<Br/>27<Br/>28<Br/>29<Br/>30</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.libelle_livraison}>Livraison à :</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.data_client}>RESIDENCE HAUCOURT<Br/>21 A RUE DE LA MEUSE<Br/>06-77-53-62-36<Br/>nmartinois@aeim54.fr<Br/>54860 HAUCOURT MOULAINE</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.data_client_2}>RESIDENCE HAUCOURT<Br/>21 A RUE DE LA MEUSE<Br/>54860 HAUCOURT MOULAINE<Br/>FRANCE</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.data_client_3}>Tél : 03 82 25 88 93                                    TVA : FR27775615594<Br/>Fax : 03 82 25 25 41                                   Siret : 77561559400378</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.base}>Base soumise à TVA :</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.montant_ht}>Montant HT</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.unite_eur_1}>EUR</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.tva1_bis}>TVA 1:</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.unite_eur_2}>EUR</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.total}>TOTAL</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.unite_eur_3}>EUR</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.tva}>TVA 1:</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.corps_tva}>20%                900,00</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.reglement}>REGL : Virement à 30  jours Fin de Mois</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.dir}>Direction</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.service}>Service</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.VISA1}>VISA</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.VISA2}>VISA</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.banque}>Banque : Banque Populaire 14707 00020 32621318438 04<Br/>Siret : 775 615 594 00113     APE: 8810C    TVA : 27775615594</Text>
      </View>
      </Page>
    </Document>
  );
}


export default App;