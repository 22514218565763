import React, { useState, useEffect, useContext } from "react";
import { Card, Container, Row, Col, Table, Form, Button, Modal } from "react-bootstrap";
import { AuthContext } from '../components/LoginPage/LoginPage.js';
import axios from 'axios';
import useWindowSize from '../assets/config/WindowSize.js';
import { FaTrash, FaPen, FaFileExcel } from "react-icons/fa";
import * as XLSX from 'xlsx';
import Select from 'react-select';

function ExtractionOpportunites() {
  const [opportunites, setOpportunites] = useState([]);
  const [searchClient, setSearchClient] = useState([]);
  const [searchSociete, setSearchSociete] = useState([]);
  const [searchDate, setSearchDate] = useState("");
  const [selectedEtab, setSelectedEtab] = useState("");
  const [etablissements, setEtablissements] = useState([]);
  const { userDetails } = useContext(AuthContext);
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  const [showModal, setShowModal] = useState(false);
  const [selectedOpportunite, setSelectedOpportunite] = useState(null);

  useEffect(() => {
    if (userDetails?.mail) {
      axios.get(`/etab_by_mail?email=${userDetails.mail}`)
        .then(response => {
          const etabData = response.data.reduce((acc, etab) => {
            acc[etab.etab_soc] = { etab_nom: etab.etab_nom };
            return acc;
          }, {});
          setEtablissements(etabData);
          if (response.data.length > 0) setSelectedEtab(response.data[0].etab_soc);
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [userDetails.mail]);

  useEffect(() => {
    if (selectedEtab) {
      axios.get(`/opportunite?soc=${selectedEtab}`)
        .then(response => {
          setOpportunites(response.data);
        })
        .catch(error => console.error('Error fetching opportunities:', error));
    }
  }, [selectedEtab]);

  const handleSearchClientChange = (selectedOptions) => setSearchClient(selectedOptions || []);
  const handleSearchSocieteChange = (selectedOptions) => setSearchSociete(selectedOptions || []);
  const handleSearchDateChange = (e) => setSearchDate(e.target.value);

  const filteredOpportunites = opportunites.filter(opportunite => {
    const matchClient = searchClient.length === 0 ||
      searchClient.some(s => opportunite.raisonSociale.toLowerCase().includes(s.value.toLowerCase()));
    const matchSociete = searchSociete.length === 0 ||
      searchSociete.some(s => opportunite.societe.toLowerCase().includes(s.value.toLowerCase()));
    const matchDate = !searchDate || 
      new Date(opportunite.date).toLocaleDateString() === new Date(searchDate).toLocaleDateString();
    return matchClient && matchSociete && matchDate;
  });

  const handleEtabChange = (e) => {
    setSelectedEtab(e.target.value);
  };

 const handleExport = () => {
    const exportData = [];
  
    filteredOpportunites.forEach(opportunite => {
      // Ligne principale pour l'opportunité
      exportData.push({
        "Société": opportunite.soc,
        "Raison Sociale": opportunite.raisonSociale,
        "Date": opportunite.dateCreation ? new Date(opportunite.dateCreation).toLocaleDateString() : '',
        "Responsable": opportunite.responsable,
        "Type": "", // Colonne vide pour distinguer les événements
        "Nom Événement": "",
        "Date Événement": "",
        "Descriptif Événement": ""
      });
  
      // Ajouter les événements associés
      opportunite.evenements.forEach(event => {
        exportData.push({
          "Société": "", // Garder vide pour les sous-lignes
          "Raison Sociale": "",
          "Date": "",
          "Responsable": "",
          "Type": event.type,
          "Nom Événement": event.nom,
          "Date Événement": event.date ? new Date(event.date).toLocaleDateString() : '',
          "Descriptif Événement": event.descriptif,
        });
      });
    });
  
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Opportunités");
  
    // Appliquer un style de colonnes
    ws['!cols'] = [
      { wch: 15 }, // Société
      { wch: 20 }, // Raison Sociale
      { wch: 15 }, // Date
      { wch: 20 }, // Responsable
      { wch: 15 }, // Type
      { wch: 25 }, // Nom Événement
      { wch: 15 }, // Date Événement
      { wch: 30 }, // Descriptif Événement
    ];
  
    XLSX.writeFile(wb, "opportunites_et_evenements.xlsx");
  };  

  const handleShowModal = (opportunite) => {
    setSelectedOpportunite(opportunite);
    setShowModal(true);
  };

  const confirmDelete = () => {
    if (selectedOpportunite) {
      axios.post('/delete_opportunite', {
        soc: selectedEtab,
        id: selectedOpportunite.id
      })
      .then(() => {
        setOpportunites(opportunites.filter(o => o.id !== selectedOpportunite.id));
      })
      .catch(error => console.error('Erreur lors de la suppression:', error));
    }
    setShowModal(false);
  };

  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4" style={welcomeStyle}>Extraction des Opportunités</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="4">
                    <Form.Group>
                        <Form.Label>Etablissement</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedEtab}
                            onChange={handleEtabChange}
                            isInvalid={selectedEtab === ""}
                        >
                            <option value="">Sélectionner un établissement</option>
                            {Object.keys(etablissements).map((key) => (
                            <option key={key} value={key}>
                                {key + " - " + etablissements[key].etab_nom}
                            </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>Raison Sociale</Form.Label>
                    <Select
                      isMulti
                      value={searchClient}
                      onChange={handleSearchClientChange}
                      options={opportunites.map(op => ({
                        value: op.raisonSociale,
                        label: op.raisonSociale
                      }))}
                      placeholder="Rechercher une raison sociale"
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={searchDate}
                      onChange={handleSearchDateChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Table */}
              <Table responsive className="table-hover table-striped">
                <thead>
                  <tr>
                    <th>Société</th>
                    <th>Raison Sociale</th>
                    <th>Date</th>
                    <th>Responsable</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOpportunites.length > 0 ? (
                    filteredOpportunites.map((opportunite, index) => (
                      <tr key={index}>
                        <td>{opportunite.soc}</td>
                        <td>{opportunite.raisonSociale}</td>
                        <td>{new Date(opportunite.dateCreation).toLocaleDateString()}</td>
                        <td>{opportunite.responsable}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">Aucune opportunité trouvée.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Button variant="success" onClick={handleExport}>
                <FaFileExcel /> Exporter en Excel
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Êtes-vous sûr de vouloir supprimer cette opportunité ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Annuler</Button>
          <Button variant="danger" onClick={confirmDelete}>Supprimer</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ExtractionOpportunites;