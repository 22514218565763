import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { getUserDetails } from "../assets/config/graph";
import { FaInfoCircle } from 'react-icons/fa';
import { Row, Col, Card, Form, Modal } from "react-bootstrap"; // Assurez-vous d'importer Modal
import "bootstrap/dist/css/bootstrap.min.css"; // Importer les styles Bootstrap
import axios from 'axios';

const UserProfilePage = () => {
  const [companyData, setCompanyData] = useState({});
  const [Etabs, setEtabs] = useState([]);
  const { instance, accounts } = useMsal();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstLogin, setFirstLogin] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [company, setCompany] = useState("");
  const [CompanyGrp, setCompanyGrp] = useState("");
  const [defaultCompany, setDefaultCompany] = useState("");

  // Ajouter un état pour gérer l'affichage du modal
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios.get('/etab')
      .then(response => {
        setEtabs(response.data);
        const data = {};
        response.data.forEach(etab => {
          data[etab.soc] = {
            group: etab.groupe,
            companyName: etab.nom,
            address: etab.rue1,
            city: etab.ville,
            postalCode: etab.code_postal,
            country: etab.pays,
          };
        });
        setCompanyData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (accounts.length > 0) {
      const fetchData = async () => {
        try {
          const userDetailsResponse = await getUserDetails(instance, accounts);
          setUserDetails(userDetailsResponse);
          setLoading(false);

          const atIndex = userDetailsResponse.mail.indexOf("@");
          if (atIndex !== -1) {
            const id = userDetailsResponse.mail.substring(0, atIndex);
            setUserId(id);
            setEmail(userDetailsResponse.mail);
          }

          setFirstName(userDetailsResponse.givenName || "");
          setLastName(userDetailsResponse.surname || "");

          if (userDetailsResponse.firstLogin) {
            setFirstLogin(userDetailsResponse.firstLogin);
          }
        } catch (error) {
          console.error("Error fetching user details: ", error);
          setError(error.message);
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [instance, accounts]);

  const handleCompanyChange = (e) => {
    const selectedCompany = e.target.value;
    setCompany(selectedCompany);

    if (companyData[selectedCompany]) {
      const data = companyData[selectedCompany];
      setCompanyGrp(data.group);
      setCompanyName(data.companyName);
      setAddress(data.address);
      setCity(data.city);
      setPostalCode(data.postalCode);
      setCountry(data.country);
    } else {
      setCompanyGrp("");
      setCompanyName("");
      setAddress("");
      setCity("");
      setPostalCode("");
      setCountry("");
    }
  };

  const forceCompanyValue = (value) => {
    setCompany(value);

    if (companyData[value]) {
      const data = companyData[value];
      setCompanyGrp(data.group);
      setCompanyName(data.companyName);
      setAddress(data.address);
      setCity(data.city);
      setPostalCode(data.postalCode);
      setCountry(data.country);
    } else {
      setCompanyGrp("");
      setCompanyName("");
      setAddress("");
      setCity("");
      setPostalCode("");
      setCountry("");
    }
  };

  useEffect(() => {
    if (email) {
      axios.get('/user_by_mail?email=' + email)
        .then(response => {
          setDefaultCompany(response.data[0]);
          setUserId(response.data[0].id)
          if (companyData && companyData.hasOwnProperty(response.data[0].soc)) {
            forceCompanyValue(response.data[0].soc);
          }
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [email, companyData]);

  const handleIdentityChange = (e) => {
    const fullName = e.target.value.split(" ");
    if (fullName.length >= 2) {
      setFirstName(fullName[0]);
      setLastName(fullName[1]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (defaultCompany) {
      fetch('/users_update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: userId,
          groupe: CompanyGrp,
          soc: company,
          nom: lastName,
          prenom: firstName,
          email: email,
          su: true
        })
      })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
    } else {
      fetch('/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          groupe: CompanyGrp,
          soc: company,
          nom: lastName,
          prenom: firstName,
          email: email,
          su: true
        })
      })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
    }
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      // Afficher le modal à la place de l'alerte
      setShowModal(true);
    }, 1000);
  };

  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  const welcomeStyle2 = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#8e7788',
    marginBottom: '10px',
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <div className="container-fluid mt-4">
      <Row>
        <Col md={6} className={isMobile ? "order-2" : "order-md-1"}>
          <Card className="mb-4">
            <Card.Body>
              <h2 className="card-title" style={welcomeStyle}>Informations Utilisateur</h2>
              {loading ? (
                <p className="card-text">Chargement des informations utilisateur...</p>
              ) : userDetails ? (
                <Form>
                  <Form.Group className="mt-2">
                    {isMobile ? (
                      <>
                        <Row>
                          <Col xs={6} className="pr-1">
                            <Form.Label className="mt-2">Nom :</Form.Label>
                            <Form.Control
                              type="text"
                              value={`${lastName}`}
                              onChange={handleIdentityChange}
                              required
                              disabled
                            />
                          </Col>
                          <Col xs={6} className="pl-1">
                            <Form.Label className="mt-2">Prénom :</Form.Label>
                            <Form.Control
                              type="text"
                              value={`${firstName}`}
                              onChange={handleIdentityChange}
                              required
                              disabled
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col xs={12}>
                            <Form.Label>Email :</Form.Label>
                            <Form.Control
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                              disabled
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Form.Group as={Row} className="mt-2">
                          <Form.Label column md={3} className="mt-2">Nom :</Form.Label>
                          <Col md={9}>
                            <Form.Control
                              type="text"
                              value={`${lastName}`}
                              onChange={handleIdentityChange}
                              required
                              disabled
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mt-2">
                          <Form.Label column md={3} className="mt-2">Prénom :</Form.Label>
                          <Col md={9}>
                            <Form.Control
                              type="text"
                              value={`${firstName}`}
                              onChange={handleIdentityChange}
                              required
                              disabled
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mt-2">
                          <Form.Label column md={3} className="mt-2">Email :</Form.Label>
                          <Col md={9}>
                            <Form.Control
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </>
                    )}
                  </Form.Group>
                </Form>
              ) : (
                <p className="card-text">Erreur de chargement des informations utilisateur.</p>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className={isMobile ? "order-1" : "order-md-2"}>
          <Col md={12} className="d-flex flex-column justify-content-center">
            <div className="welcome-text">
              <h2 className="text-center" style={welcomeStyle2}>
                <span style={welcomeStyle}>Bienvenue</span> {firstName} {lastName}
              </h2>
              {/* Affiche le texte uniquement si le champ "Nom entreprise" est vide */}
              {!companyName && (
                <p className="text-center" style={{ fontSize: '1.2rem', color: '#113c60' }}>
                  Veuillez sélectionner un établissement pour accéder au site...
                </p>
              )}
            </div>
            <div className="partner-logos">
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col md={12} className="text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img src={require("../assets/img/erp-8835050-7201956.png")} alt="Logo Partenaire 2" style={{ maxWidth: '120%', maxHeight: '155px', height: 'auto' }} />
                </Col>
              </Row>
            </div>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Body>
              <h2 className="card-title" style={welcomeStyle}>Informations de l'Entreprise</h2>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={4}>
                    <Form.Group as={Row} className="mt-3">
                      <Form.Label>Société</Form.Label>
                      <Col>
                        <Form.Control
                          as="select"
                          value={company}
                          onChange={handleCompanyChange}
                          required
                        >
                          <option value="">Sélectionnez une société</option>
                          {Object.keys(companyData).map((key) => (
                            <option key={key} value={key}>
                              {key}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={8}>
                    <Form.Group as={Row} className="mt-3">
                      <Form.Label>Nom Entreprise</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          required
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Form.Group as={Row} className="mt-1">
                      <Form.Label>Adresse</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          required
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group as={Row} className="mt-1">
                      <Form.Label>Ville</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          required
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group as={Row} className="mt-1">
                      <Form.Label>Code Postal</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                          required
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group as={Row} className="mt-1">
                      <Form.Label>Pays</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          required
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="text-center mt-3">
                    <button type="submit" className="btn btn-primary" disabled={submitting}>
                      {submitting ? "Soumission en cours..." : "Soumettre"}
                    </button>
                  </Col>
                </Row>
              </Form>
              {error && <p className="card-text text-danger">Erreur: {error}</p>}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal de confirmation */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title><FaInfoCircle style={{marginBottom: '4px' }}></FaInfoCircle> Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Informations utilisateur validées avec succès !
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Fermer</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserProfilePage;