import React from "react";
import { Container, Row, Col, Overlay } from "react-bootstrap";
import { AiOutlineLineChart, AiOutlineTeam, AiOutlineFileText } from "react-icons/ai";

// Styles personnalisés
const featureStyle = {
  textAlign: "center",
  margin: "20px 0",
};

const featureIconStyle = {
  fontSize: "3rem",
  color: "#113c60",
  marginBottom: "10px",
};

const featureTextStyle = {
  fontFamily: "'Century Gothic', sans-serif",
  fontSize: "1.2rem",
  color: "#333",
};

const headerTextStyle = {
  fontFamily: "'Century Gothic', sans-serif",
  fontSize: "2rem",
  fontWeight: "bold",
  color: "#113c60",
  textAlign: "center",
  marginBottom: "40px",
};

const container = {
  marginBottom: "70px",
};

function Home() {
  return (
    <Container fluid className="py-5">
      <Row className="justify-content-center"  style={container}>
        <Col md="10" className="text-center">
          <h2 className="mt-1">
            <span style={headerTextStyle}>Bienvenue</span> sur notre ERP
          </h2>
          <p className="lead mb-4">
            Gerez efficacement vos ressources et processus metier.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={3} style={featureStyle}>
          <AiOutlineLineChart style={featureIconStyle} />
          <p style={featureTextStyle}>Suivi en temps réel des indicateurs clés</p>
        </Col>
        <Col md={3} style={featureStyle}>
          <AiOutlineTeam style={featureIconStyle} />
          <p style={featureTextStyle}>Gestion efficace des équipes et des tâches</p>
        </Col>
        <Col md={3} style={featureStyle}>
          <AiOutlineFileText style={featureIconStyle} />
          <p style={featureTextStyle}>Automatisation des processus métiers</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;