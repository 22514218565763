import Accueil from "./views/Accueil.js";
import ArticlesHistorique from "./views/ArticlesHistorique.js"; // Assurez-vous que le chemin est correct
import ArticlesCreation from "./views/ArticlesCreation.js"; // Assurez-vous que le chemin est correct
import DevisCreation from "./views/DevisCreation.js";
import DevisHistorique from "./views/DevisHistorique.js";
import Tiers from "./views/Tiers.js";
import UserProfilePage from "./views/UserProfilePage.js";
import Extraction from "./views/Extraction.js";
import CRM from "./views/CRM.js";
import Htmltopdf from "./views/htmltopdf.js";


const routes = [
  {
    path: "/accueil",
    name: "Accueil",
    icon: "nc-icon nc-bank",
    component: Accueil,
    layout: "/admin"
  },
  {
    path: "/articles/creation",
    name: "Articles", // S'assure que le nom est correct pour le sidebar
    icon: "nc-icon nc-bag",
    component: ArticlesCreation,
    layout: "/admin"
  },
  {
    path: "/devis/creation",
    name: "Devis",
    icon: "nc-icon nc-notes",
    component: DevisCreation,
    layout: "/admin"
  },
  {
    path: "/crm/opportunite",
    name: "CRM",
    icon: "nc-icon nc-app",
    component: CRM,
    layout: "/admin"
  },
  {
    path: "/crm/extraction",
    name: "Extraction",
    icon: "nc-icon nc-layers-3",
    component: Extraction,
    layout: "/admin",
    showInSidebar: false
  },
  {
    path: "/tiers",
    name: "Tiers",
    icon: "nc-icon nc-circle-09",
    component: Tiers,
    layout: "/admin"
  },
  {
    path: "/devis/htmltopdf",
    name: "DevisPDF",
    icon: "nc-icon nc-notes",
    component: Htmltopdf,
    layout: "/admin",
    showInSidebar: false
  },
  {
    path: "/user-profile",
    name: "User Profile",
    component: UserProfilePage,
    layout: "/admin",
    showInSidebar: false
  },
  {
    path: "/devis/historique",
    name: "Historique des devis",
    icon: "nc-icon nc-time-alarm",
    component: DevisHistorique,
    layout: "/admin",
    showInSidebar: false
  },
  {
    path: "/articles/historique",
    name: "Historique des articles",
    icon: "nc-icon nc-time-alarm",
    component: ArticlesHistorique,
    layout: "/admin",
    showInSidebar: false
  },
];

export default routes;
