import React, { useState, useEffect, useContext } from "react";
import { Card, Container, Row, Col, Table, Form, Button, Modal } from "react-bootstrap";
import { AuthContext } from '../components/LoginPage/LoginPage.js';
import axios from 'axios';
import useWindowSize from '../assets/config/WindowSize.js';
import { FaTrash, FaPen, FaPrint } from "react-icons/fa";
import { useLocation, useHistory } from 'react-router-dom';

function ConsultationDevis() {
  const [devis, setDevis] = useState([]);
  const [searchDocument, setSearchDocument] = useState("");
  const [SelectedEtab, setSelectedEtab] = useState("");
  const { userDetails } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  const [showModal, setShowModal] = useState(false);
  const [selectedDevis, setSelectedDevis] = useState(null);
  const [dateCreation, setDateCreation] = useState(""); // Ajouter l'état pour la date

  useEffect(() => {
    if (userDetails?.mail) {
      axios.get('/etab_by_mail?email=' + userDetails.mail)
        .then(response => {
          const etab = response.data[0];
          setSelectedEtab(etab.default_soc);
        })
        .catch(error => console.error('Erreur lors de la récupération des données :', error));
    }
  }, [userDetails.mail]);

  useEffect(() => {
    if (SelectedEtab) {
      axios.get('/searchEstimate', {
        params: { soc: SelectedEtab }
      })
      .then((response) => {
        setDevis(response.data);
      })
      .catch((error) => console.error('Erreur lors de la récupération des devis :', error));
    }
  }, [SelectedEtab]);

  const handleSearchChange = (e) => {
    setSearchDocument(e.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); 
    const day = String(date.getDate()).padStart(2, '0'); 
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear(); 
    
    return `${day}/${month}/${year}`; 
  };


  const filteredDevis = devis.filter((devisItem) =>
    devisItem.numero.toLowerCase().includes(searchDocument.toLowerCase()) ||
    devisItem.nom.toLowerCase().includes(searchDocument.toLowerCase())
  );

  const handleEdit = (devisItem) => {
    history.push({
      pathname: '/admin/devis/creation',
      state: { devisNumero: devisItem.numero }
    });
  };  

  const fetchAndPrint = (devisId) => {
    history.push('/admin/devis/htmltopdf', { devisId });
  };

  const handleDelete = (devisItem) => {
    axios.post('/delete_estimate', {
      data: {
        soc: SelectedEtab,  // Société à partir de l'état SelectedEtab
        numero: devisItem.numero  // Numéro du devis à supprimer
      }
    })
    .then((response) => {
      console.log(response.data); // Afficher la réponse du serveur pour confirmer la suppression
      setDevis(devis.filter(d => d.numero !== devisItem.numero)); // Retirer le devis supprimé de la liste affichée
    })
    .catch(error => {
      console.error('Erreur lors de la suppression du devis :', error);
      alert('Erreur lors de la suppression du devis.');
    });
  };
  
  

  const handleShowModal = (devisItem) => {
    setSelectedDevis(devisItem);
    setShowModal(true);
  };

  const confirmDelete = () => {
    if (selectedDevis) {
      handleDelete(selectedDevis); // Supprime le devis sélectionné
    }
    setShowModal(false); // Ferme le modal de confirmation
  };  

  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  const renderStep = () => {
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4" style={welcomeStyle}>Historique des Devis</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form.Group as={Row} controlId="searchDevis">
                  <Form.Label column sm="2">Recherche Devis</Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="text"
                      placeholder="Entrez le numéro du devis ou le nom du client"
                      value={searchDocument}
                      onChange={handleSearchChange}
                    />
                  </Col>
                </Form.Group>
                <Table responsive className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Numéro</th>
                      <th>Client</th>
                      <th>Section</th>
                      <th>Montant HT</th>
                      <th>Date de création</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredDevis.length > 0 ? (
                      filteredDevis.map((devisItem, index) => (
                        <tr key={index}>
                          <td>{devisItem.numero}</td>
                          <td>{devisItem.nom}</td>
                          <td>{devisItem.section}</td>
                          <td>{devisItem.montant_ht}</td>
                          <td>{formatDate(devisItem.date_creation)}</td>
                          <td style={{ textAlign: "right" }}>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                              <Button 
                                className="btn btn-primary me-2" 
                                onClick={() => fetchAndPrint('devis-123')} // Exemple : ID du devis
                              >
                                <FaPrint  />
                              </Button>
                              <Button
                                variant="warning"
                                className="me-2"
                                onClick={() => handleEdit(devisItem)}
                              >
                                <FaPen /> 
                              </Button>
                              <Button
                                variant="danger"
                                className="me-2"
                                onClick={() => handleShowModal(devisItem)}
                              >
                                <FaTrash /> 
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">Aucun devis correspondant trouvé.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation de suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>Êtes-vous sûr de vouloir supprimer ce devis ? Cette action est irréversible.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Annuler</Button>
            <Button variant="danger" onClick={confirmDelete}>Supprimer</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  };

  return (
    <Container fluid>
      {isMobile ? renderStep() : (
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4" style={welcomeStyle}>Historique des Devis</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form.Group as={Row} controlId="searchDevis">
                  <Form.Label column sm="2">Recherche Devis</Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="text"
                      placeholder="Entrez le numéro du devis ou le nom du client"
                      value={searchDocument}
                      onChange={handleSearchChange}
                    />
                  </Col>
                </Form.Group>
                <Table responsive className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Numéro</th>
                      <th>Client</th>
                      <th>Section</th>
                      <th>Montant HT</th>
                      <th>Date de création</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredDevis.length > 0 ? (
                      filteredDevis.map((devisItem, index) => (
                        <tr key={index}>
                          <td>{devisItem.numero}</td>
                          <td>{devisItem.nom}</td>
                          <td>{devisItem.section}</td>
                          <td>{devisItem.montant_ht}</td>
                          <td>{formatDate(devisItem.date_creation)}</td>
                          <td style={{ textAlign: "right" }}>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button 
                                className="btn btn-primary me-2" 
                                onClick={() => fetchAndPrint('devis-123')} // Exemple : ID du devis
                              >
                                <FaPrint  />
                              </Button>
                              <Button
                                variant="warning"
                                className="me-2"
                                onClick={() => handleEdit(devisItem)}
                              >
                                <FaPen /> 
                              </Button>
                              <Button
                                variant="danger"
                                className="me-2"
                                onClick={() => handleShowModal(devisItem)}
                              >
                                <FaTrash /> 
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">Aucun devis correspondant trouvé.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Êtes-vous sûr de vouloir supprimer ce devis ? Cette action est irréversible.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Annuler</Button>
          <Button variant="danger" onClick={confirmDelete}>Supprimer</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ConsultationDevis;