import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import routes from "../../routes.js";
import { AuthContext } from '../LoginPage/LoginPage.js';

function AdminNavbar() {
  const { handleLogout, userProfile } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 992);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const handleAccountClick = (e) => {
    e.preventDefault();
    history.push("/admin/user-profile");
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div>
          <img src={require("../../assets/img/logo.png")} alt="..." style={{ width: '100px', height: 'auto', marginLeft: '10px' }} />
        </div>
        <Button aria-controls="basic-navbar-nav" className="mr-2 navbar-toggler" onClick={mobileSidebarToggle}>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Button>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" navbar>
            {!isMobile && (
              <>
                <Nav.Item>
                  <Nav.Link
                    className="m-1"
                    href="#pablo"
                    onClick={handleAccountClick}
                  >
                    <span className="nc-icon nc-single-02" style={{ fontSize: '1.5em' }}></span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="m-1"
                    href="#logout"
                    onClick={handleLogout}
                  >
                    <span className="nc-icon nc-button-power" style={{ fontSize: '1.5em' }}></span>
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AdminNavbar;
